#search-box {
  margin-bottom: 20px;
}

#pagination {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

#search-footer {
  padding: 10px 0;
}
