.alternate-expressions, .expression-variations, .expression-synonyms {
  margin-bottom: 10px;
  color: #444444;

  .chip {
    display: inline-block;
    font-style: italic;
  }
}

.expression-definition-wrapper {

  $margin-right: 5px;
  $margin-left: 20px;
  $gray-text: #5A5A5A;

  @mixin apply-margins() {
    margin-left: $margin-left;
    margin-right: $margin-right;
  }

  .expression-definition {
    display: flex;
    flex-wrap: nowrap;
    .expression-entry-number {
      color: $gray-text;
    }

    .expression-definition-text {
      @include apply-margins();
    }
  }

  .expression-example {
    @include apply-margins();
  }

  .expression-synonyms {
    @include apply-margins();
  }

  .expression-metadata {
    @include apply-margins();
    color: $gray-text;
  }

  .expression-images {
    @include apply-margins();
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }

  .expression-videos {
    @include apply-margins();
  }

  p.title {
    font-weight: bold;
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.85);
  }
}
