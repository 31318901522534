/*! instantsearch.js 2.10.4 | © Algolia Inc. and other contributors; Licensed MIT | github.com/algolia/instantsearch.js */
.ais-clear-all--link, .ais-current-refined-values--clear-all, .ais-price-ranges--button, .ais-range-input--submit {
    color: #fff;
    display: inline-block;
    background: #3369e7;
    border-radius: 4px;
    font-size: 12px;
    text-decoration: none;
    padding: 4px 8px
}

.ais-geo-search--clear, .ais-geo-search--redo {
    color: #fff;
    display: inline-block;
    background: #3369e7;
    font-size: 12px;
    text-decoration: none
}

.ais-clear-all--link:hover, .ais-current-refined-values--clear-all:hover, .ais-geo-search--clear:hover, .ais-geo-search--redo:hover, .ais-price-ranges--button:hover, .ais-range-input--submit:hover {
    text-decoration: none;
    color: #fff;
    background: #184ecd
}

.ais-clear-all--link-disabled, .ais-current-refined-values--clear-all-disabled {
    opacity: .5;
    pointer-events: none
}

.ais-current-refined-values--clear-all {
    margin-bottom: 5px
}

.ais-current-refined-values--item, .ais-hierarchical-menu--item, .ais-menu--item {
    font-size: 14px;
    line-height: 30px
}

.ais-refinement-list--item {
    font-size: 14px
}

.ais-star-rating--item, .ais-toggle--item {
    font-size: 14px;
    line-height: 30px
}

.ais-geo-search--toggle-label {
    line-height: 30px
}

.ais-current-refined-values--link, .ais-geo-search--toggle-label, .ais-hierarchical-menu--link, .ais-menu--link, .ais-price-ranges--link, .ais-refinement-list--label, .ais-star-rating--link, .ais-toggle--label {
    color: #3e82f7;
    text-decoration: none
}

.ais-current-refined-values--link:hover, .ais-geo-search--toggle-label:hover, .ais-hierarchical-menu--link:hover, .ais-menu--link:hover, .ais-price-ranges--link:hover, .ais-refinement-list--label:hover, .ais-star-rating--link:hover, .ais-toggle--label:hover {
    color: #184ecd;
    text-decoration: none
}

.ais-current-refined-values--count, .ais-hierarchical-menu--count, .ais-menu--count, .ais-refinement-list--count, .ais-star-rating--count, .ais-toggle--count {
    background: rgba(39, 81, 175, .1);
    border-radius: 31px;
    color: #3e82f7;
    padding: 2px 10px
}

.ais-hierarchical-menu--item__active > div > .ais-hierarchical-menu--link, .ais-menu--item__active > div > .ais-menu--link, .ais-refinement-list--item__active > div > .ais-geo-search--toggle-label, .ais-refinement-list--item__active > div > .ais-refinement-list--label, .ais-refinement-list--item__active > div > .ais-toggle--label {
    font-weight: 700
}

.ais-hierarchical-menu--item__active > div > .ais-hierarchical-menu--link::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.ais-hierarchical-menu--link {
    position: relative
}

.ais-hierarchical-menu--link::after {
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 13' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5l5 4.98-5 5.02' stroke='%23697782' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' opacity='.4'/></svg>") no-repeat center center/contain;
    content: ' ';
    display: block;
    position: absolute;
    top: calc(50% - 14px / 2);
    right: -22px;
    height: 14px;
    width: 14px
}

select.ais-hits-per-page-selector, select.ais-menu-select--select, select.ais-numeric-selector, select.ais-sort-by-selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%23BFC7D8' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>") no-repeat center right 16px/10px;
    box-shadow: 0 1px 1px 0 rgba(85, 95, 110, .2) !important;
    border: solid 1px #d4d8e3 !important;
    border-radius: 4px;
    color: #697782;
    font-size: 12px;
    transition: background .2s ease, box-shadow .2s ease;
    padding: 8px 32px 8px 16px;
    outline: 0
}

.ais-menu button, .ais-refinement-list div > button {
    background: 0 0;
    border: 0;
    cursor: pointer;
    font-size: 11px
}

.ais-refinement-list--item {
    line-height: 24px
}

.ais-geo-search--toggle-label, .ais-refinement-list--label, .ais-toggle--label {
    cursor: pointer
}

.ais-geo-search--toggle-label input[type=checkbox], .ais-geo-search--toggle-label input[type=radio], .ais-refinement-list--label input[type=checkbox], .ais-refinement-list--label input[type=radio], .ais-toggle--label input[type=checkbox], .ais-toggle--label input[type=radio] {
    margin-right: 5px
}

.ais-pagination {
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(85, 95, 110, .2);
    border: solid 1px #d4d8e3;
    border-radius: 4px;
    display: inline-block;
    padding: 8px 16px;
    width: auto;
    margin: 0;
}

.ais-pagination--item {
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 28px
}

.ais-pagination--item:hover {
    background: rgba(39, 81, 175, .1)
}

.ais-pagination--item__disabled {
    color: #bbb;
    opacity: .5;
    pointer-events: none;
    visibility: visible
}

.ais-pagination--item__active {
    background: #3369e7
}

.ais-pagination--item__active .ais-pagination--link {
    color: #fff
}

.ais-pagination--link {
    color: #697782;
    display: block;
    text-decoration: none;
    width: 100%
}

.ais-pagination--link:hover {
    color: #3369e7;
    text-decoration: none
}

.ais-price-ranges--item {
    font-size: 14px;
    line-height: 24px
}

.ais-price-ranges--item__active {
    font-weight: 700
}

.ais-price-ranges--form {
    margin-top: 10px
}

.ais-price-ranges--input {
    background: #fff;
    box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, .2);
    border: solid 1px #d4d8e3;
    border-radius: 4px;
    outline: 0
}

.ais-price-ranges--button {
    border: 0;
    outline: 0;
    margin-left: 5px;
    position: relative;
    top: -2px
}

.ais-range-input--inputMax, .ais-range-input--inputMin {
    background: #fff;
    box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, .2);
    border: solid 1px #d4d8e3;
    border-radius: 4px;
    outline: 0
}

.ais-range-input--submit {
    border: 0;
    outline: 0;
    margin-left: 5px;
    position: relative;
    top: -2px
}

.ais-range-slider--handle {
    border: 1px solid #3369e7
}

.ais-range-slider .rheostat-progress {
    background-color: #3369e7 !important
}

.ais-search-box {
    display: inline-block;
    position: relative;
    height: 46px;
    white-space: nowrap;
    font-size: 14px
}

.ais-search-box--input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font: inherit;
    background: #fff;
    color: #000;
    display: inline-block;
    border: 1px solid #d4d8e3;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(85, 95, 110, .2);
    transition: box-shadow .4s ease, background .4s ease;
    padding: 10px 10px 10px 35px;
    vertical-align: middle;
    white-space: normal;
    height: 100%;
    width: 100%
}

.ais-search-box--input:focus {
    box-shadow: none;
    outline: 0
}

.ais-search-box--reset {
    fill: #bfc7d8;
    top: calc(50% - 12px / 2);
    right: 13px
}

.ais-search-box--loading-indicator-wrapper, .ais-search-box--magnifier {
    fill: #bfc7d8;
    left: 12px;
    top: calc(50% - 18px / 2)
}

.ais-search-box--loading-indicator-wrapper svg, .ais-search-box--magnifier svg {
    height: 18px;
    width: 18px
}

.ais-stats {
    color: #697782;
    font-size: 14px;
    opacity: .6
}

.ais-toggle--item__active {
    font-weight: 700
}

.ais-breadcrumb--root .ais-breadcrumb--label {
    display: inline;
    color: #3369e7
}

.ais-breadcrumb--root .ais-breadcrumb--separator {
    color: #3369e7
}

.ais-breadcrumb--root .ais-breadcrumb--home {
    display: inline;
    color: #3369e7
}

.ais-breadcrumb--root .ais-breadcrumb--home div, .ais-breadcrumb--root .ais-breadcrumb--label div, .ais-breadcrumb--root .ais-breadcrumb--separator div {
    display: inline
}

.ais-breadcrumb--root .ais-breadcrumb--disabledLabel {
    color: #444;
    display: inline
}

.ais-breadcrumb--root .ais-breadcrumb--separator {
    position: relative;
    display: inline-block;
    height: 14px;
    width: 14px
}

.ais-breadcrumb--root .ais-breadcrumb--separator::after {
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 13' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5l5 4.98-5 5.02' stroke='%23697782' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' opacity='.4'/></svg>") no-repeat center center/contain;
    content: ' ';
    display: block;
    position: absolute;
    top: 2px;
    height: 14px;
    width: 14px
}

.ais-geo-search {
    position: relative
}

.ais-geo-search--clear {
    box-shadow: 0 1px 1px 0 rgba(85, 95, 110, .2);
    border: solid 1px #d4d8e3;
    border-radius: 4px;
    padding: 8px 15px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.ais-geo-search--clear:hover, .ais-geo-search--redo:hover {
    cursor: pointer
}

.ais-geo-search--control {
    position: absolute;
    top: 10px;
    left: 50px
}

.ais-geo-search--redo, .ais-geo-search--toggle-label {
    box-shadow: 0 1px 1px 0 rgba(85, 95, 110, .2);
    border: solid 1px #d4d8e3;
    border-radius: 4px
}

.ais-geo-search--toggle-label {
    font-size: 12px;
    background: #fff;
    padding: 0 15px
}

.ais-geo-search--redo {
    padding: 8px 15px
}

.ais-geo-search--redo:disabled {
    background: #a0b8f3
}

[class^=ais-], [class^=ais-] > *, [class^=ais-] > ::after, [class^=ais-] > ::before {
    box-sizing: border-box
}

.ais-header {
    border-bottom: 2px solid #eee;
    font-size: .8em;
    margin: 0 0 6px;
    padding: 0 0 6px;
    text-transform: uppercase
}
