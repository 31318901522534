#noscript {
  background-color: crimson;
  color: white;
  text-align: center;
  padding: 10px 0;
}

footer {
  background-color: #f9f9f9;
  ul {
    list-style: none;
    margin: 0;

    li {
      margin: 5px 0;
    }
  }
}
