.page-content {
  .wrapper {
    .home {
      img#landing-page-photo {
        width: 100%;
        max-width: 100%;
        height: 450px;
        object-fit: cover;
        object-position: 0 -130px;
      }
    }
    .post-list {
      column-count: 1;
      column-gap: 1em;
      .post-card {
        display: inline-block;
        width: 100%;
      }
    }
    /* each post */
    .post {
      max-width: 60vw;
      .post-header {}
      .post-content {
        p > img {
          margin: 1rem 0;
        }
      }
    }
  }
}

@media screen and (min-width: $on-large) {
  .page-content {
    .wrapper {
      .post-list {
        column-count: 2;
      }
    }
  }
}
