.post-card {
  .post-content {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    transition: 0.3s;
    padding: 10px 20px 4px 20px;
    margin: 10px 0;

    &:hover {
      box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.2);
    }
  }
}
